<template>
  <div id="app">
    <div class="body fixed mobile">
      <Spinner :spin="spin"/>
      <section class="header activity">
        <div class="flexH width">
          <a class="icon iconButton third" @click.prevent="goBackTo">
            <font-awesome-icon
              icon="fa-solid fa-chevron-left"
              size="lg"
            ></font-awesome-icon>
          </a>
        </div>
        <span class="fL nowrap">簽到紀錄</span>
        <div class="flexH width right">
            <!-- <a class="textButton fM third" @click.prevent="$router.push('/campaign-records')">獲獎紀錄</a> -->
        </div>
      </section>
      <section class="main">
        <table class="table">
          <thead>
            <th>天數</th>
            <th>簽到</th>
          </thead>
          <tbody class="text-center">
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ item.day }}</td>
              <td>{{ item.status }}</td>
            </tr>
          </tbody>
        </table>
        <div class="flex">
          <button
            v-if="list.length > 0"
            class="button text-white border-0 flex-1 fM float rounded-2xl"
            @click="submitDailySign"
            :disabled="btnDisabled">
            {{ btnLabel }}
          </button>
        </div>
      </section>
    </div>
    <AlertDialog
      v-if="showAlert"
      :title="alert.title"
      :content="alert.message"
      @close="onCloseAlertDialog"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapState } from "vuex";

import Spinner from '@/components/Spinner';
import AlertDialog from '@/components/Dialog/AlertDialog.vue'

export default {
  name: "DailySign",
  components: {
    Spinner,
    AlertDialog
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      spin: false,
      list: [],
      signListFromApi: [],
      n: 5,
      showAlert: false,
      alert: {
        title: '',
        message: ''
      }
    }
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    isSignToday() {
      const today = this.formatDate(new Date());
      return this.signListFromApi.some(item => item.date === today);
    },
    isSignAll() {
      if (this.list.length === 0) {
        return false;
      }
      return this.list.every(item => item.status === '已簽到');
    },
    btnDisabled() {
      if (this.isSignToday) {
        return true;
      }
      return false;
    },
    btnLabel() {
      if (this.isSignAll) {
        return '您已領取獎勵';
      }

      if (this.isSignToday) {
        return '今日已簽到';
      }

      return '每日簽到';
    },
    isOverToday() {
      const today = moment();
      const endTime = moment(this.qnaCampaign.campaignEndTime * 1000);
      return endTime.isBefore(today);
    },
  },
  async created() {
    await this.fetchCampaign()
  },
  methods: {
    getCampaignAPI() {
      let config = {
        url: `${this.apiHost}/campaigns/v1/merchants/yunlin/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true&publisherType=municipal`,
        method: "GET"
      };
      return this.$http(config);
    },
    getCampaigns() {
      return (
        this.getCampaignAPI()
          .then((res) => {
            this.campaigns = res.data.campaigns;
            return res;
          })
      );
    },
    fetchCampaign() {
      let vm = this;
      let that = vm;
      let campaignId = this.$route.params.id;
      that.getCampaigns()
        .then(() => {
          that.qnaCampaign = that.campaigns.find(
            (campaign) => campaign.campaignId == campaignId
          );
          console.log("qnaCampaign: ", that.qnaCampaign);
          let signDays = _.get(that.qnaCampaign, 'missions.0.settings.signDays', '0');
          signDays = parseInt(signDays, 10);
          this.n = signDays;
          this.fetchSteps()
        })
        .catch((getCampaignsErr) => {
          console.log("getCampaignsErr: ", getCampaignsErr);
        });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 保證月份有兩位數
      const day = String(date.getDate()).padStart(2, '0'); // 保證日期有兩位數
      return `${year}-${month}-${day}`;
    },
    findConsecutiveDates(list) {
      const sortedList = list.sort((a, b) => new Date(b.date) - new Date(a.date));
      const consecutiveList = [];
      
      for (let i = 0; i < sortedList.length; i++) {
        if (i === 0) {
          consecutiveList.push(sortedList[i]);
        } else {
          const prevDate = new Date(sortedList[i - 1].date);
          const currentDate = new Date(sortedList[i].date);
          const differenceInDays = (prevDate - currentDate) / (1000 * 60 * 60 * 24);

          // 如果日期差值不是 1 天，則停止
          if (differenceInDays === 1) {
            consecutiveList.push(sortedList[i]);
          } else {
            break;
          }
        }
      }
      return consecutiveList;
    },
    fillWithToday(list) {
      const sortedList = list.sort((a, b) => {
        return new Date(a.date) - new Date(b.date)
      })
      const lastDateInList = new Date(sortedList[sortedList.length - 1].date);
      const fillUntil = new Date()
      while (this.formatDate(lastDateInList) < this.formatDate(fillUntil)) {
        lastDateInList.setDate(lastDateInList.getDate() + 1); // 日期往後加一天
        sortedList.push({ date: this.formatDate(lastDateInList) }); // 將新的日期加入 fakeList
      }

      return sortedList
    },
    fillDates (list, targetLength) {
      const result = [...list];
      
      // 獲取最後一個日期
      let lastDate = new Date(result[result.length - 1].date);
      
      // 直到陣列長度達到指定數字，繼續添加日期
      while (result.length < targetLength) {
        lastDate.setDate(lastDate.getDate() + 1); // 增加一天
        result.push({ date: lastDate.toISOString().split('T')[0] }); // 將新日期格式化並推入陣列
      }
      
      return result;
    },
    containsTodayOrYesterday(list)  {
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1); 
      return list.some(item => {
        const currentDate = new Date(item.date);
        const isToday = currentDate.toDateString() === today.toDateString();
        const isYesterday = currentDate.toDateString() === yesterday.toDateString();
        return isToday || isYesterday;
      });
    },
    async fetchSteps() {
      // this.signListFromApi = [
      //   {
      //     date: '2024-10-18',
      //   },
      //   // {
      //   //   date: '2024-10-19',
      //   // },
      //   {
      //     date: '2024-10-21',
      //   }, 
      //   // {
      //   //   date: '2024-10-22',
      //   // }, 
      //   {
      //     date: '2024-10-20',
      //   },
      // ]

      // store-api/core/campaigns/v1/merchants/{merchantId}/daily/sign/list/uuid/{uuid}/member_id/{memberId}
      try {
        var config = {
          method: "get",
          url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/daily/sign/list/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}`,
          headers: {
              "Content-Type": "application/json"
          },
        };
        const response = await this.$http(config)
  
        this.signListFromApi = response.data.data;
      } catch (error) {
        this.signListFromApi = []
      }

      let continueList = this.findConsecutiveDates(this.signListFromApi);
      const isContainsTodayOrYesterday = this.containsTodayOrYesterday(continueList);
      if (!isContainsTodayOrYesterday) {
        continueList = [
          {
            date: this.formatDate(new Date())
          },
        ]
      }

      const list = this.fillWithToday(continueList);
      const listDates = this.fillDates(list, this.n);
      this.list = listDates.map((item, index) => {
        const isToday = item.date === this.formatDate(new Date());
        return {
          day: isToday ? '今天' :`第 ${index + 1} 天`,
          date: item.date,
          status: this.signListFromApi.some(record => record.date === item.date) ? '已簽到' : '未簽到'
        }
      })
    },
    goBackTo() {
      this.$router.go(-1);
    },
    async submitDailySign() {
      if (this.isOverToday) {
        this.alert = {
          title: '提示',
          message: '活動已結束'
        }
        this.showAlert = true
        return
      }
      var config = {
          method: "post",
          url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/daily/sign/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}`,
          headers: {
              "Content-Type": "application/json"
          },
      };
      try {
          const response = await this.$http(config)
          if (response.data.message === "Signed today") {
              this.alert = {
                  title: '提示',
                  message: '今日已簽到'
              }
          } else {
              this.alert = {
                  title: '提示',
                  message: '今日簽到成功'
              }
          }
          this.fetchSteps()
          this.showAlert = true
      } catch (error) {
          console.log('error', error);
      }
    },
    onCloseAlertDialog() {
      this.showAlert = false
      this.alert = {}
      if (this.isOverToday) {
        this.$router.push('/home')
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.table {
  border-collapse: collapse; /* 合并边框 */
  border-radius: 20px;
  overflow: hidden; /* 确保圆角效果 */
  thead {
    background-color: var(--c003);
    th {
      padding: 10px 0;
    }
  }
  td {
    padding: 10px 0;
    text-align: center;
  }
  tr {
    border-bottom: 1px solid #ddd !important;
  }

  tr:last-child {
    border-bottom: none;
  }
}

.body .button.float {
  width: calc(100% - 36px);
  margin: 0 auto;
  max-width: 748px;
}
</style>
